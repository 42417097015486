import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import FsLightbox from 'fslightbox-react';
import _ from 'lodash';

import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'

import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Hyphenated from 'react-hyphen';

import BackgroundImage from 'gatsby-background-image';

import seapig, { REQUIRED } from 'seapig';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
//import './css/zoom.css';

import Layout from '../components/layout';
import { useSiteMetadata } from '../components/meta/site';

import { GridRow, WideGridRow } from '../components/widgets/grid';

import Play from './svg/logos/play_badge.svg';
import AppStore from './svg/logos/app_store.svg';

const openAppStore = () => {
  window.open('https://itunes.apple.com/gb/app/switchsmith/id1194663008?mt=8');
}

const openPlayStore = () => {
  window.open('https://play.google.com/store/apps/details?id=com.relops.switchsmith&hl=en&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1');
}

const powerParams = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  grabCursor: true,
  speed: 600,
  parallax: true,
  parallaxEl: {
    el: '.parallax-bg',
    value: '-23%'
  },
}

const smallParams = {
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  // speed: 600,
  // parallax: true,
  // parallaxEl: {
  //   el: '.parallax-bg',
  //   value: '-23%'
  // },
}

const Pitch = () => {

  const { site, talking_sm, talking_lg } = useSiteMetadata();
  const { siteMetadata } = site;
  const { pitch: { why, bullet } } = siteMetadata;

  const sources = [
    talking_sm.childImageSharp.fluid,
    {
      ...talking_lg.childImageSharp.fluid,
      media: `(min-width: 30em)`
    }
  ];

  const [visible, setVisible] = useState(false);

  return (
    <BackgroundImage
      style={{
        width: '100%',
        backgroundPosition: 'bottom center',
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',
      }}
      fluid={sources}
      backgroundColor='#EEF4F7'
    >
      <div className="row pt4 pb4 pt6-l pb6-l bb shadow-5 b--black-10">
        <div className="col-xs-6 col-lg-6"></div>
        <div className="col-xs-6 col-lg-4">
          <h2 className="tl">{why}</h2>
          <h3 className="tl">{bullet}</h3>
          <div className="row mt4">

            {/* <div className="col-xs-11 col-sm-6 col-md-6 col-lg-6">
              <Link to='/trial' className="f5 fw6 ttu tracked grow hover-yellow no-underline br-pill ph3 pv2 dib white bg-mid-gray">
                Start Free
              </Link>
            </div> */}

            <div className="col-xs-1 col-lg-0">
              <div className="h2 w1">&#x200b;</div>&#x200b;
            </div>

            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 flex items-center">
              <a href="#" className="underline" onClick={() => setVisible(!visible)}>30 second video</a>
              <FsLightbox
                toggler={visible}
                type='video'
                customSources={[
                  <iframe
                    src="https://player.vimeo.com/video/384748920"
                    width="640" height="360"
                    frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                ]}
              />
            </div>

          </div>
        </div>
      </div>
    </BackgroundImage>
  );
}

const HomePage = ({}) => {

  const { soundbites, site, usecases } = useSiteMetadata();

  const { siteMetadata } = site;
  const { quip, pitch: { how } } = siteMetadata;

  const bites = soundbites.edges.map(({node: { path, soundbite, quid }}) => ({path, soundbite, quid}));
  const sorted = _.orderBy(bites, ['soundbite.priority'], ['desc']);

  const sortedUseCases = _.orderBy(usecases.nodes, ['importance'], ['desc']);

  return (
    <div className="pb4-ns graph-white" style={{backgroundImage: './pages/img/stock/istockphoto-938099630-2048x2048'}}>

      <Helmet>
        <link rel="canonical" href="https://switchsmith.com" />
      </Helmet>

      <GridRow>

        <div className="mt4">

          <div className="tc">
            <h1>{how}</h1>
          </div>
        </div>

      </GridRow>

      <WideGridRow>
          <div className="row">

            <div className="col-xs-0 col-sm-0 col-md-12 col-lg-12 mb4">

              <Swiper className="" {...powerParams}>
                {sorted.map(({ path, quid: { why }, soundbite: { tag, icon, screenshot }, quid: { what: { description } } }, idx) => (
                  <div key={idx}>
                    <PowerCell path={path} icon={icon} title={tag} shot={screenshot} description={description} why={why} />
                  </div>
                ))}
              </Swiper>

            </div>

            <div className="col-xs-12 col-sm-12 col-md-0 col-lg-0 center-xs">

              <div className="mt4">
                <Swiper className="" {...smallParams}>
                  {sorted.map(({ path, soundbite: { short, tag, icon, screenshot }, quid: { what: { description } } }, idx) => (
                    <div key={idx}>
                      <SmallCell path={path} icon={icon} title={short || tag} shot={screenshot} description={description} />
                    </div>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
      </WideGridRow>

      {/* <GridRow>
        <div className="mt0-ns mt4 mb4 row center-xs center-sm center-md center-lg">
          <div className="col-xs-5 col-sm-4 col-md-4 col-lg-4 flex items-center justify-around">
            <AppStore style={{ height: '40px' }} onClick={openAppStore} />
          </div>
          <div className="col-xs-5 col-sm-4 col-md-4 col-lg-4 flex items-center justify-around">
            <Play style={{ height: '60px' }} onClick={openPlayStore} />
          </div>
        </div>
      </GridRow> */}

      <div className="pt5-ns pb5">
      {sortedUseCases.map((uc, idx) => {
        const even = (idx % 2 == 0);
        const bg = even ? 'gradient-yellow' : 'graph-white'
        return (
          <div key={idx} className={`${bg}`}>
            <GridRow>
              <UseCase idx={idx} {...uc} ltr={even} />
            </GridRow>
          </div>
        );
      })}
      </div>

    </div>
  );
}

const UseCaseFrame = ({children, ltr}) => {
  const {
    proseChildren,
    imageChildren,
  } = seapig(children, {
    prose: REQUIRED,
    image: REQUIRED,
  });

  const direction = ltr ? 'reverse' : '';

  return (
    <div className={`row pt5 pb5 ${direction} middle-sm middle-md middle-lg`}>
      <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        {imageChildren}
      </div>
      <div className="col-xs-0 col-sm-1 col-md-1 col-lg-1"></div>
      <div className="mt0-ns mt4 col-xs-12 col-sm-7 col-md-7 col-lg-7">
        {proseChildren}
      </div>
    </div>
  );

}

const UseCase = ({topic, description, bullets, ltr, stock }) =>
  <UseCaseFrame ltr={ltr}>

    <div prose="true">
      <div className="flex flex-row items-center">
        <FontAwesomeIcon className="fa-2x" icon={['far', 'highlighter']} />
        <h1 className="ml3">{topic}</h1>
      </div>

      <div><p>{description}</p></div>
      <div>
        <ul className="fa-ul">
          {bullets.map((b,idx) => <li key={idx}><FontAwesomeIcon icon={['fad', 'check']} listItem/>{b}</li>)}
        </ul>
      </div>
    </div>

    <div image="true">
      {stock && <Img style={{ width: '100%' }} fluid={stock.lg.fluid} alt={topic} /> }
    </div>

  </UseCaseFrame>

const SmallCell = ({path, title, shot, description}) => {

  const [isZoomed, setIsZoomed] = useState(false)

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  const { childImageSharp } = shot;
  const { xs, md } = childImageSharp;

  const fluid = isZoomed ? md : xs;

  return (
    <div className="shadow-5 ba b--black-10 br2 pt3 pl2 pr2 ml3 mr3 mb5">

      <div className="mb3" data-swiper-parallax="-100">
        <Link to={`${path}#index`} className="near-black hover-mid-gray grow dib">
          <div className="row">
            <div className="col-lt-xs">
              <h2 className="">{title}</h2>
            </div>
            <div className="col-gte-xs">
              <Hyphenated>
                <h5 className="h2 w3">{title}</h5>
              </Hyphenated>
            </div>
          </div>
        </Link>
      </div>

      <div className="h-60 w-60 center" data-swiper-parallax="-400">
        {typeof window !== 'undefined' &&
          <ControlledZoom zoomMargin={10} wrapStyle={{ width: '100%' }} isZoomed={isZoomed} onZoomChange={handleZoomChange}>
            <Img style={{ width: '100%' }} fluid={fluid} alt={title} />
          </ControlledZoom>
        }
      </div>

      <div className="mt4 pb4" data-swiper-parallax="-250">{description}</div>

    </div>
  )
}

const PowerCell = ({path, icon, title, shot, description, why }) => {

  const [isZoomed, setIsZoomed] = useState(false)

  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  const { childImageSharp } = shot;
  const { md, lg } = childImageSharp;

  const fluid = isZoomed ? lg : md;

  return (
    <div className="row pa4 mb4">
      <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
      <div className="col-xs-10 col-sm-10 col-lg-10 col-lg-10">
        <div className="row shadow-5 ba b--black-10 br2 pa4">
          <div className="col-xs-6 col-sm-5 col-md-5 col-lg-5">
            <div className="mb4" data-swiper-parallax="-100">
              <Link to={`${path}`} className="near-black hover-mid-gray grow dib">
                <h1>{title}</h1>
              </Link>
            </div>
            <div className="" data-swiper-parallax="-250"><p>{description}</p></div>
            <div className="row">
              <div className="col-xs-0 col-sm-0 col-md-12 col-lg-12" data-swiper-parallax="-250"><p>{why}</p></div>
            </div>

          </div>
          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-3"></div>
          <div className="col-xs-5 col-sm-5 col-md-5 col-lg-3" data-swiper-parallax="-400">
            {/* <div className="noscript">
              <Img style={{ width: '100%' }} fluid={md} alt={title} />
            </div> */}
            {typeof window !== 'undefined' &&
              <ControlledZoom wrapStyle={{ width: '100%' }} isZoomed={isZoomed} onZoomChange={handleZoomChange}>
                <Img style={{ width: '100%' }} fluid={fluid} alt={title} />
              </ControlledZoom>
              }
          </div>
        </div>
      </div>
      <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
    </div>
  );
}

const IndexPage = () =>
  <Layout>
    <Pitch headline />
    <HomePage />
  </Layout>

export default IndexPage;

