import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => useStaticQuery(query)

const query = graphql`
  query {
    talking_lg: file(relativePath: { regex: "/938099630-not-cropped/"}) {
      childImageSharp {
        fluid(quality: 35, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    talking_sm: file(relativePath: { regex: "/938099630-cropped/"}) {
      childImageSharp {
        fluid(quality: 100 , maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    conversation: file(relativePath: { regex: "/noun_conversation_738688_000000/"} , extension: { eq: "svg"}) {
      publicURL
    }
    plug: file(relativePath: { regex: "/noun_plug_728480_000000/"} , extension: { eq: "svg"}) {
      publicURL
    }
    help_desk: file(relativePath: { regex: "/noun_help_desk_technician_1200622_000000/"} , extension: { eq: "svg"}) {
      publicURL
    }
    usecases: allUsecase {
      nodes {
        topic
        importance
        description
        bullets
        stock {
          lg: childImageSharp {
            fluid(quality: 35, maxWidth: 650) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    soundbites: allFeature(filter: { soundbite: { priority: { ne: null }}}) {
      edges {
        node {
          path
          importance
          soundbite {
            tag
            short
            screenshot {
              childImageSharp {
                xs: fluid(quality: 40, maxWidth: 150, traceSVG: {
                  color: "#DEE2E6",
                  turdSize: 0,
                  blackOnWhite: true
                }) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
                md: fluid(quality: 40, maxWidth: 250, traceSVG: {
                  color: "#DEE2E6",
                  turdSize: 0,
                  blackOnWhite: true
                }) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
                lg: fluid(quality: 50, maxWidth: 600, traceSVG: {
                  color: "#DEE2E6",
                  turdSize: 0,
                  blackOnWhite: true
                }) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
            icon {
              publicURL
            }
            priority
          }
          headline {
            heading
            icon {
              publicURL
            }
          }
          quid {
            why
            what {
              topic
              description
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        quip
        pitch {
          why
          how
          bullet
        }
      }
    }
  }
`